import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TimelineLite, Bounce } from "gsap";

import { prepareText } from "../../../utils/helpers";
import Container from "../../container";
import { MainText, MainTitle, MainSubtitle } from "../../intro/common";

import IntroChat from "./inline/IntroChat.svg";
import { ScheduleDemoLink } from "../../shared/ScheduleDemo";

const IntroContent = styled(Container)`
  display: flex;
  position: relative;

  @media only screen and (min-width: 700px) {
    margin-top: 50px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    text-align: center;
    font-size: 68px;
  }
`;

const ProductAssetImage = styled.div`
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const LABEL_ANIMATION_FROM = { opacity: '0', transform: 'translateX(-20px)' };
const LABEL_ANIMATION_TO = { opacity: '1', transform: 'translateX(0)' };

const Intro = ({ data }) => {
  const { intro } = data;

  useEffect(() => {
    const tl = new TimelineLite();
    tl
      .to(
        '#firstMessage',
        0,
        { opacity: 1 }
      )
      .from(
        '#firstMessageMaskSquare',
        1.5,
        { opacity: 0, width: 0 }
      )
      .fromTo(
        '#firstMessageAvatar',
        1,
        { opacity: '0', transform: 'scale(0)' },
        { ease: Bounce.easeOut, opacity: '1', transform: 'scale(1)' },
        '-=0.5'
      )
      .to(
        '#secondMessage',
        0,
        { opacity: 1 }
      )
      .from(
        '#secondMessageMaskSquare',
        1.5,
        { opacity: 0, width: 0 }
      )
      .fromTo(
        '#secondMessageAvatar',
        1,
        { opacity: '0.25', transform: 'scale(0)' },
        { ease: Bounce.easeOut, opacity: '1', transform: 'scale(1)' },
        '-=0.5'
      )
      .fromTo(
        '#userIntentLabel',
        0.5,
        LABEL_ANIMATION_FROM,
        LABEL_ANIMATION_TO,
      )
      .fromTo(
        '#userIntentValue',
        0.5,
        LABEL_ANIMATION_FROM,
        LABEL_ANIMATION_TO,
        '-=0.25'
      )
      .fromTo(
        '#priorityLabel',
        0.5,
        LABEL_ANIMATION_FROM,
        LABEL_ANIMATION_TO,
        '-=0.25'
      )
      .fromTo(
        '#priorityValue',
        0.5,
        LABEL_ANIMATION_FROM,
        LABEL_ANIMATION_TO,
        '-=0.25'
      )
      .fromTo(
        '#teamLabel',
        0.5,
        LABEL_ANIMATION_FROM,
        LABEL_ANIMATION_TO,
        '-=0.25'
      )
      .fromTo(
        '#teamValue',
        0.5,
        LABEL_ANIMATION_FROM,
        LABEL_ANIMATION_TO,
        '-=0.25'
      )
  });

  return (
    <section id="intro">
      <IntroContent>
        <MainText>
          <MainTitle>{prepareText(intro.main)}</MainTitle>
          <MainSubtitle>{intro.subtitle}</MainSubtitle>
        </MainText>
        <ProductAssetImage>
          <IntroChat />
        </ProductAssetImage>
      </IntroContent>
    </section>
  );
};

Intro.propTypes = {
  data: PropTypes.object,
};

export default Intro;
