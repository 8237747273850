import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { ProductSection } from './common';
import { ShadedLockIcon, RightArrowIcon } from '../../Icons';

import Privacy from "./inline/Privacy.svg";

export const PurpleBigTopRightCorner = styled.div`
  opacity: 0.1;
  transform: rotate(33deg);
  background-image: linear-gradient(-217deg, #A5A9FF 20%, #FFFFFF 71%);
  border-radius: 264px;
  z-index: -1;
  position: absolute;
  height: 1249px;
  width: 1249px;

  @media only screen and (max-width: 700px) {
    top: 500px;
  }

  @media only screen and (min-width: 700px) {
    top: 150px;
    left: -350px;
  }
`;

const LearnMoreButton = styled(Link)`
  background: #FFFFFF;
  box-shadow: 20px 22px 50px #F1F1F2;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: #5D77E4;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
`;

const SecurityAndPrivacy = ({ data }) => {
  const { title, text, points } = data.security;

  return (
    <ProductSection
      id="securityAndPrivacy"
      icon={<ShadedLockIcon />}
      title={title}
      text={text}
      points={points}
      link={<LearnMoreButton to="/trust"><span>Learn More</span><RightArrowIcon /></LearnMoreButton>}
      asset={<Privacy />}
      background={<PurpleBigTopRightCorner />}
      reverse
    />
  );
};

SecurityAndPrivacy.propTypes = {
  data: PropTypes.object,
};

export default SecurityAndPrivacy;
