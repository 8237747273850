import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ProductSection } from './common';
import { ShadedScrollIcon } from '../../Icons';

import TicketResolutionVisual from "./inline/TicketResolution.svg";

export const PurpleBigTopRightCorner = styled.div`
  opacity: 0.1;
  transform: rotate(33deg);
  background-image: linear-gradient(-217deg, #A5A9FF 20%, #FFFFFF 71%);
  border-radius: 264px;
  z-index: -1;
  position: absolute;
  height: 1249px;
  width: 1249px;

  @media only screen and (max-width: 700px) {
    top: 500px;
  }

  @media only screen and (min-width: 700px) {
    top: -150px;
    left: -450px;
  }
`;

const TicketResolution = ({ data }) => {
  const { title, text, points } = data.ticketResolution;

  return (
    <ProductSection
      id="ticketResolution"
      icon={<ShadedScrollIcon />}
      title={title}
      text={text}
      points={points}
      asset={<TicketResolutionVisual />}
      background={<PurpleBigTopRightCorner />}
      reverse
    />
  );
};

TicketResolution.propTypes = {
  data: PropTypes.object,
};

export default TicketResolution;
