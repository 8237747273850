import styled from "styled-components";
import { Link } from "gatsby";

export const ScheduleDemoLink = styled(Link)`
  margin-top: 40px;
  display: inline-block;
  background-image: linear-gradient(-98deg, #9396ff 12%, #3e73d7 100%);
  border-radius: 28px;
  font-size: 16px;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 25px;
  font-family: 'Gotham', sans-serif;
  letter-spacing: 0.49px;
  text-shadow: 0 1px 2px rgba(0,0,0,0.10);
  box-shadow: 0 20px 70px 0 rgba(118,138,241,0.43);

  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
  }
`;
