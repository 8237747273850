import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import Container from "../../container";
import { prepareText } from "../../../utils/helpers";
import { SectionHeader, SectionDescription } from "../../common";
import { PurpleCheckIcon } from "../../Icons";

export const ProductContent = styled(Container)`
  position: relative;
  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}

  @media only screen and (max-width: 700px) {
    text-align: center;
    margin-top: 20px;
    padding-top: 30px;
  }

  @media only screen and (min-width: 700px) {
    display: flex;
    align-items: center;
    padding-top: 50px;
    margin-top: 100px;
  }
`;

export const ProductColumn = styled.div`
  ${props =>
    props.flex
      ? css`
          flex: ${props.flex};
        `
      : css`
          flex: 1;
        `}

  @media only screen and (min-width: 700px) {
    padding: 0 40px;
  }
`;

const PurpleCheckIconWrapper = styled.div`
  flex: 0 0 auto;
`;

const Points = styled.div`
  border-top: 1px solid #D5DCE270;
  margin: 20px 0;
  text-align: left;

  @media only screen and (max-width: 700px) {
    padding: 0 30px;
  }
`;

const SinglePoint = styled.div`
  color: #5D77E5;
  margin: 20px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PointText = styled.span`
  margin-left: 10px;
`;

export function ProductSection({ id, icon, title, text, link, points, asset, reverse, background }) {
  return (
    <section id={id}>
      <ProductContent reverse={reverse}>
        <ProductColumn flex={3}>
          {icon}
          <SectionHeader>{
            Array.isArray(title) ?
              title.map(t => <div key={t}>{prepareText(t)}</div>) :
              prepareText(title)
            }
          </SectionHeader>
          {text.map((t, i) => (
            <SectionDescription key={i}>{prepareText(t)}</SectionDescription>
          ))}
          {link}
          <Points>
            {points && points.map(p =>
              <SinglePoint key={p}>
                <PurpleCheckIconWrapper><PurpleCheckIcon /></PurpleCheckIconWrapper>
                <PointText>{p}</PointText>
              </SinglePoint>)}
          </Points>
        </ProductColumn>
        <ProductColumn flex={4}>{asset}</ProductColumn>
        {background}
      </ProductContent>
    </section>
  );
}

ProductSection.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.array,
  link: PropTypes.element,
  points: PropTypes.array,
  asset: PropTypes.element,
  reverse: PropTypes.bool,
  background: PropTypes.element,
};
