import React from 'react';
import styled from 'styled-components';

const ColorSpan = styled.span`
  color: #9293FF;
`;

const Block = styled.div`
  @media only screen and (max-width: 700px) {
    padding: 0 20px;
  }
`;

export function prepareText(txt) {
  const firstIndex = txt.indexOf("*");
  const lastIndex = txt.lastIndexOf("*") + 1;

  let styledTxt = <ColorSpan>{txt.substring(firstIndex + 1, lastIndex - 1)}</ColorSpan>;

  return (
    <Block>
      {txt.substring(0, firstIndex)}
      {styledTxt}
      {txt.substring(lastIndex, txt.length)}
    </Block>
  );
}
