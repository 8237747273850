import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import shopify from "../../../assets/logos/shopify.png";
import intercom from "../../../assets/logos/intercom.png";
import zendesk from "../../../assets/logos/zendesk.png";
import kustomer from "../../../assets/logos/kustomer.png";
import oracle from "../../../assets/logos/oracle.png";
import livechatLogo from "../../../assets/logos/livechat.png";
import freshdesk from "../../../assets/logos/freshdesk.png";
import salesforce from "../../../assets/logos/salesforce.png";
import crisp from "../../../assets/logos/crisp.png";

import { ShadedDoubleRightArrowIcon, RightArrowIcon } from '../../Icons';
import { SectionHeader, AssetImage, SectionDescription } from '../../common';
import { prepareText } from '../../../utils/helpers';
import Container from '../../container';
import { ImageGrid } from '../../common';
import { CardTitle } from '../trust/card';
import { Link } from 'gatsby';

const AugmentContent = styled(Container)`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const AugmentDescription = styled(SectionDescription)`
  @media only screen and (min-width: 700px) {
    max-width: 500px;
  }
`;

const AugmentCards = styled.div`
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  text-align: left;

  @media only screen and (min-width: 700px) {
    display: flex;
    flex-direction: row;
  }
`;

const AugmentCardContent = styled.div`
  border-radius: 4px;
  margin: 10px;
  flex: 1;

  &:hover {
    background-color: white;
    box-shadow: 40px 52px 100px #EDEDF2;

    a {
      color: #788DF1;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 700px) {
    margin: 20px 0;
    padding: 20px;
  }

  @media only screen and (min-width: 700px) {
    padding: 30px;
  }
`;

const PlatformRequestContent = styled.div`
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #AEB3C160;
  color: #788DF1;
  visibility: hidden;

  ${AugmentCardContent}:hover & {
    visibility: visible;
  }
`;

const PlatformRequestText = styled(Link)`
  margin-right: 20px;
  text-decoration: none;
`;

const AugmentCard = ({ platforms, title, description }) => (
  <AugmentCardContent>
    <ImageGrid>
      {
        platforms.map(p =>
          <AssetImage key={p} src={p} />
        )
      }
    </ImageGrid>
    <CardTitle>
      {title}
    </CardTitle>
    <SectionDescription>
      {description}
    </SectionDescription>
  </AugmentCardContent>
);

AugmentCard.propTypes = {
  platforms: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
};

const Augment = ({ data }) => {
  const { title, text, crm, livechat, ecommerce } = data.augment;

  crm.platforms = [
    salesforce, zendesk, kustomer, freshdesk, oracle
  ];

  livechat.platforms = [
    zendesk, crisp, intercom, oracle, livechatLogo
  ];

  ecommerce.platforms = [
    shopify
  ];

  return (
    <section id="augment">
      <AugmentContent>
        <ShadedDoubleRightArrowIcon />
        <SectionHeader>
          {prepareText(title)}
        </SectionHeader>
        <AugmentDescription>{prepareText(text)}</AugmentDescription>
        <AugmentCards>
          <AugmentCard
            platforms={crm.platforms}
            title={crm.title}
            description={crm.description}
          />
          <AugmentCard
            platforms={livechat.platforms}
            title={livechat.title}
            description={livechat.description}
          />
          <AugmentCard
            platforms={ecommerce.platforms}
            title={ecommerce.title}
            description={ecommerce.description}
          />
        </AugmentCards>
      </AugmentContent>
    </section>
  );
};

Augment.propTypes = {
  data: PropTypes.object,
};

export default Augment;
