import React from 'react';
import PropTypes from 'prop-types';

import { ProductSection } from './common';
import { ShadedProfileCheckIcon } from '../../Icons';

import EmpowerVisual from "./inline/Empowerment.svg";

const Empower = ({ data }) => {
  const { title, text, points } = data.empower;

  return (
    <ProductSection
      id="empower"
      icon={<ShadedProfileCheckIcon />}
      title={title}
      text={text}
      points={points}
      asset={<EmpowerVisual />}
    />
  );
};

Empower.propTypes = {
  data: PropTypes.object,
};

export default Empower;
