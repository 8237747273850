import React from 'react';
import PropTypes from 'prop-types';

import { ShadedProfileCheckIcon } from '../../Icons';
import { ProductSection } from './common';

import PropietaryViz from "./inline/Propietary.svg";

const Propietary = ({ data }) => {
  const { title, text, points } = data.propietary;

  return (
    <ProductSection
      id="propietary"
      icon={<ShadedProfileCheckIcon />}
      title={title}
      text={text}
      points={points}
      asset={<PropietaryViz />}
    />
  );
};

Propietary.propTypes = {
  data: PropTypes.object,
};

export default Propietary;
