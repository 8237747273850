import React from "react";
import styled from "styled-components";

import data from "../data/product.yaml";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Intro from "../components/pages/product/intro";
import Tailored from "../components/pages/product/tailored";
import TicketResolution from "../components/pages/product/ticketResolution";
import Empower from "../components/pages/product/empower";
import SecurityAndPrivacy from "../components/pages/product/securityAndPrivacy";
import Insights from "../components/pages/product/insights";
import Augment from "../components/pages/product/augment";
import Propietary from "../components/pages/product/propietary";

const GrayBigTopRightCorner = styled.div`
  opacity: 0.6;
  transform: rotate(-60deg);
  background: rgba(255,255,255,0.20);
  box-shadow: 80px -80px 100px 0 rgba(10,13,36,0.05);
  border-radius: 174px;
  z-index: -10;
  position: absolute;

  height: 876px;
  width: 876px;

  @media only screen and (max-width: 700px) {
    display: none;
  }

  @media only screen and (min-width: 700px) {
    top: 200px;
    left: -100px;
  }
`;

const PurpleBigTopRightCorner = styled.div`
  opacity: 0.2;
  transform: rotate(-60deg);
  background-image: linear-gradient(-183deg, #A5A9FF 15%, #FFFFFF 100%);
  border-radius: 210px;
  z-index: -1;
  position: absolute;
  height: 951px;
  width: 951px;

  @media only screen and (max-width: 700px) {
    top: 0px;
    right: -600px;
  }

  @media only screen and (min-width: 700px) {
    top: -100px;
    right: -100px;
  }
`;

const ProductPage = () => (
  <Layout
    headerBackgrounds={[
      <GrayBigTopRightCorner key='grayBigTopRightCorner' />,
      <PurpleBigTopRightCorner key='purpleBigTopRightCorner' />,
    ]}
  >
    <SEO
      title="AI Support Agent for Your Business | Empower Customer Service with Artificial Intelligence."
      metaTitle="Empower your Customer Service Team with the Artificial Intelligence Built by Percept AI"
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />

    <Intro data={data} />
    <Tailored data={data} />
    <TicketResolution data={data} />
    <Empower data={data} />
    <Insights data={data} />
    <Propietary data={data} />
    <SecurityAndPrivacy data={data} />
    <Augment data={data} />
  </Layout>
);

export default ProductPage;
