import React from 'react';
import PropTypes from 'prop-types';

import { ProductSection } from './common';
import { ShadedTailoredIcon } from '../../Icons';

import WorkingLady from "./inline/WorkingLady.svg";

const Tailored = ({ data }) => {
  const { title, text, points } = data.tailored;

  return (
    <ProductSection
      id="tailored"
      icon={<ShadedTailoredIcon />}
      title={title}
      text={text}
      points={points}
      asset={<WorkingLady />}
    />
  );
};

Tailored.propTypes = {
  data: PropTypes.object,
};

export default Tailored;
