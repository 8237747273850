import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SectionDescription } from '../../common';

const CardContent = styled.div`
  margin-top: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;

  ${props =>
    !props.transparent &&
      css`
        padding: 30px;
        background: #FFFFFF;
        box-shadow: 0 0 100px rgba(11,13,27,0.20);
        border-radius: 10px;
      `};


  ${props => props.shiftCenterOnMobile && css`
    @media only screen and (max-width: 700px) {
      text-align: center;
    }
  `}
`;

const CardIconHolder = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex: 0 0 auto;

  ${props => props.shiftCenterOnMobile && css`
    @media only screen and (max-width: 700px) {
      justify-content: center;
    }
  `}
`;

export const CardTitle = styled.div`
  flex: 0 0 auto;
  font-size: 20px;
  color: #30323D;
  margin: 6px 0 12px 0;
`;

const CardDescription = styled(SectionDescription)`
  flex: 1 0 auto;
`;

const Card = ({ icon, title, description, link, transparent, shift }) => {
  return (
    <CardContent transparent={transparent} shiftCenterOnMobile={shift}>
      <CardIconHolder shiftCenterOnMobile={shift}>{icon}</CardIconHolder>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      {link}
    </CardContent>
  );
};

Card.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.element,
  transparent: PropTypes.bool,
  shift: PropTypes.bool,
};

export default Card;
