import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ShadedProfileIcon, CalendarIcon, RightArrowIcon } from '../../Icons';
import { ProductSection } from './common';

import Dashboard from "../../../assets/product/Dashboard.png";
import { Link } from 'gatsby';
import { AssetImage } from '../../common';

export const PurpleBigTopRightCorner = styled.div`
  opacity: 0.1;
  transform: rotate(33deg);
  background-image: linear-gradient(-217deg, #A5A9FF 20%, #FFFFFF 71%);
  border-radius: 264px;
  z-index: -1;
  position: absolute;
  height: 1249px;
  width: 1249px;

  @media only screen and (max-width: 700px) {
    top: 500px;
  }

  @media only screen and (min-width: 700px) {
    top: 100px;
    right: -600px;
  }
`;

const ScheduleDemoWrapper = styled(Link)`
  font-size: 16px;
  color: #5D77E4;
  letter-spacing: 0.52px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const RoundCalendarIconWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 15px 40px 0 rgba(28,40,86,0.15);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const RoundCalendarIcon = () => (
  <RoundCalendarIconWrapper>
    <CalendarIcon />
  </RoundCalendarIconWrapper>
);

const RightArrowIconWrapper = styled.div`
  margin-left: 5px;
  transform: scale(0.8);
`;

const Insights = ({ data }) => {
  const { title, text, points } = data.insights;

  return (
    <ProductSection
      id="insights"
      icon={<ShadedProfileIcon />}
      title={title}
      text={text}
      points={points}
      asset={<AssetImage src={Dashboard} />}
      background={<PurpleBigTopRightCorner />}
      reverse
    />
  );
};

Insights.propTypes = {
  data: PropTypes.object,
};

export default Insights;
